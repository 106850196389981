import {initSentry} from "./sentry.js";
import {initInertia} from "./inertia.js";

import Popper from "popper.js"
import jQuery from "jquery"
import axios from "axios"
import Vue from "vue"
import VueScrollTo from "vue-scrollto"

import "v2-datepicker/lib/index.css" // v2 need to import css
import V2Datepicker from "v2-datepicker"

import BootstrapVue from "bootstrap-vue"

//// Vue components used in blade templates (view name in comment)
import AssignmentPage from "~components/teacher/assign/AssignmentPage.vue" // assign_lessons
import BookCallForm from "~components/BookCallForm.vue" // page.tutor
import CircleChart from "~components/widgets/CircleChart.vue" // denver, research, score-gauge
import ClassSummaryTable from "~components/ClassSummaryTable.vue" // teacher.dashboard, classroom
import CompleteSignupForm from "~components/CompleteSignupForm.vue" // complete_signup
import DataTable from "~components/datatable/DataTable.vue" // hither and yon
import DistrictAdminPage from "~components/DistrictAdminPage.vue" // district_admin.dashboard
import DistrictAdminSettings from "~components/DistrictAdminSettings.vue" // district_admin.settings
import DistrictPricingRequestButton from "~components/DistrictPricingRequestButton.vue" // price-column
import FeedbackForm from "~components/FeedbackForm.vue" // feedback
import FluencyBenchmarkGraph from "~components/teacher/reports/graphs/FluencyBenchmarkGraph.vue" // fluency_graphs
import FluencyColdHotGraph from "~components/teacher/reports/graphs/FluencyColdHotGraph.vue" // fluency_graphs
import FluencyGoalStatus from "~components/teacher/reports/FluencyGoalStatus.vue" // detailed, detail_list
import FluencyGradeButton from "~components/teacher/fluency/FluencyGradeButton.vue" // scores_for_lesson
import FluencyReviewPage from "~components/teacher/fluency/FluencyReviewPage.vue" // teacher.recordings
import FreeSchoolTrialButton from "~components/FreeSchoolTrialButton.vue" // referral
import FreeTrialForm from "~components/FreeTrialForm.vue" // signup, home, pricing
import GenerateReportPDFButton from "~components/GenerateReportPDFButton.vue" // overall, classroom, conference
import GraphLoadingProgress from "~components/teacher/reports/graphs/GraphLoadingProgress.vue" // conference
import LeftyPopup from "~components/LeftyPopup.vue" // lefty is lord of all he surveys
import ParentConnect from "~components/ParentConnect.vue" // parent_connect
import ParentEmailManager from "~components/ParentEmailManager.vue" // students.index
import PilotRequestForm from "~components/DemoPilotRequestForm.vue" // pricing, district.  FIXME: rename file
import PreviewExam from "~components/PreviewExam.vue" // student_exam
import ProgressReportTable from "~components/teacher/reports/ProgressReportTable.vue" // 7_day
import QuoteRequestForm from "~components/QuoteRequestForm.vue" // district_pricing, request_quote
import ReportCriteriaSelector from "~components/ReportCriteriaSelector.vue" // teacher.reports.*
import ReportGraph from "~components/teacher/reports/graphs/ReportGraph.vue" // school_admin.overall, teacher.reports.parts.overall, conference
import ReviewExtension from "~components/ReviewExtension.vue" // extension_answer
import SchoolAdminTeacherList from "~components/school_admin/TeacherList.vue" // school_admin.teachers FIXME: rename file
import SearchStudents from "~components/SearchStudents.vue" // district_admin.search
import SentryContext from "~/components/util/SentryContext.vue" // layouts.teacher
import ShareEmailDialogButton from "~components/ShareEmailDialogButton.vue" // overall, classroom
import SignupForm from "~components/SignupForm.vue" // signup_form, reading.shared.top, teacher.signup
import SiteLicenseRequestButton from "~components/SiteLicenseRequestButton.vue" // price-column
import SiteNavBar from "~components/teacher/common/SiteNavBar.vue" // layouts.teacher
import SocialLoginButtons from "~components/SocialLoginButtons.vue" // teacher.login, various signup routes
import StudentGroups from "~components/StudentGroups.vue" // teacher.students.index
import StudentList from "~components/StudentList.vue" // teacher.students.index
import StudentLogin from "~components/StudentLogin.vue" // student.login
import StudentReportLessonScoreTable from "~components/StudentReportLessonScoreTable.vue" // teacher.reports.detailed
import SubscribeButton from "~components/SubscribeButton.vue" // price-column
import TeacherEmailSummary from "~components/TeacherEmailSummary.vue" // various guest routes
import VolumePricingBanner from "~components/VolumePricingBanner.vue"
import TeacherLogin from "~components/TeacherLogin.vue" // teacher.login
import TeacherQuoteForm from "~components/TeacherQuoteForm.vue" // pricing, volume-pricing-banner
import TeacherResources from "~components/TeacherResources.vue" // FIXME should be TeacherResourcesPage
import TeacherSettings from "~components/TeacherSettings.vue" // teacher.settings
import TutorialPopup from "~components/TutorialPopup.vue" // many random places
import WithServices from "~components/services/WithServices.vue" // damn near everywhere
import WorksheetTable from "~components/WorksheetTable.vue" // worksheets
//// vue-select TODO: move this into MailTemplateUi.vue (the only place it's used)
import VueSelect from "vue-select"
import "vue-select/dist/vue-select.css"
import Sandbox from "~components/Sandbox.vue"
import {CSRF_TOKEN} from "@/common/utils/fetch"
import TeacherVideoPlayer from "~components/TeacherVideoPlayer.vue"
import FluencyWpmGraph from "~components/teacher/reports/graphs/FluencyWpmGraph.vue";
import TutorVideoCarousel from "~components/TutorVideoCarousel.vue"; // page.tutor

import HomeLayout from "@/Pages/Home/Layouts/HomeLayout.vue" // default Inertia layout

Vue.component("VueSelect", VueSelect)

window.Popper = Popper
window.jQuery = jQuery
window.$ = jQuery
window.axios = axios

window.axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest"

let token = document.head.querySelector("meta[name=\"csrf-token\"]")

if (token) {
  window.axios.defaults.headers.common["X-CSRF-TOKEN"] = token.content
  CSRF_TOKEN.value = token.content  // something completely similar for useFetcher()
} else {
  console.error("CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token")
}


// end former bootstrap.js

//// Vue plugins

Vue.use(BootstrapVue)
Vue.use(V2Datepicker)
Vue.use(VueScrollTo)

//// global components used in blade templates

// Vue.component("TeacherFluencyReview", TeacherFluencyReview)
Vue.component("AssignmentPage", AssignmentPage)
Vue.component("BookCallForm", BookCallForm)
Vue.component("CircleChart", CircleChart)
Vue.component("ClassSummaryTable", ClassSummaryTable)
Vue.component("CompleteSignupForm", CompleteSignupForm)
Vue.component("DataTable", DataTable)
Vue.component("Datatable", DataTable)
Vue.component("DistrictAdminPage", DistrictAdminPage)
Vue.component("DistrictAdminSettings", DistrictAdminSettings)
Vue.component("DistrictPricingRequestButton", DistrictPricingRequestButton)
Vue.component("FeedbackForm", FeedbackForm)
Vue.component("FluencyBenchmarkGraph", FluencyBenchmarkGraph)
Vue.component("FluencyColdHotGraph", FluencyColdHotGraph)
Vue.component("FluencyGoalStatus", FluencyGoalStatus)
Vue.component("FluencyGradeButton", FluencyGradeButton)
Vue.component("FluencyReviewPage", FluencyReviewPage)
Vue.component("FluencyWpmGraph", FluencyWpmGraph)
// noinspection JSCheckFunctionSignatures
Vue.component("FreeSchoolTrialButton", FreeSchoolTrialButton)
Vue.component("FreeTrialForm", FreeTrialForm)
Vue.component("GenerateReportPDFButton", GenerateReportPDFButton)
Vue.component("GenerateReportPdfButton", GenerateReportPDFButton) // FIXME: case mismatch in filename
Vue.component("GraphLoadingProgress", GraphLoadingProgress)
Vue.component("LeftyPopup", LeftyPopup)
Vue.component("ParentConnect", ParentConnect)
// noinspection JSCheckFunctionSignatures
Vue.component("ParentEmailManager", ParentEmailManager)
Vue.component("PilotRequestForm", PilotRequestForm)
Vue.component("PreviewExam", PreviewExam)
Vue.component("ProgressReportTable", ProgressReportTable)
Vue.component("QuoteRequestForm", QuoteRequestForm)
Vue.component("ReportCriteriaSelector", ReportCriteriaSelector)
Vue.component("ReportGraph", ReportGraph)
Vue.component("ReviewExtension", ReviewExtension)
Vue.component("Sandbox", Sandbox)
Vue.component("SchoolAdminTeacherList", SchoolAdminTeacherList)
Vue.component("SearchStudents", SearchStudents)
Vue.component("SentryContext", SentryContext)
Vue.component("ShareEmailDialogButton", ShareEmailDialogButton)
Vue.component("SignupForm", SignupForm)
Vue.component("SiteLicenseRequestButton", SiteLicenseRequestButton)
Vue.component("SiteNavBar", SiteNavBar)
Vue.component("SocialLoginButtons", SocialLoginButtons)
Vue.component("StudentGroups", StudentGroups)
Vue.component("StudentList", StudentList)
Vue.component("StudentLogin", StudentLogin)
Vue.component("StudentReportLessonScoreTable", StudentReportLessonScoreTable)
Vue.component("SubscribeButton", SubscribeButton)
Vue.component("TeacherEmailSummary", TeacherEmailSummary)
Vue.component("VolumePricingBanner", VolumePricingBanner)
Vue.component("TeacherLogin", TeacherLogin)
// noinspection JSCheckFunctionSignatures
Vue.component("TeacherQuoteForm", TeacherQuoteForm)
Vue.component("TeacherResources", TeacherResources)
Vue.component("TeacherSettings", TeacherSettings)
Vue.component("TutorialPopup", TutorialPopup)
Vue.component("TutorVideoCarousel", TutorVideoCarousel)
Vue.component("TeacherVideoPlayer", TeacherVideoPlayer)
Vue.component("WithServices", WithServices)
Vue.component("WorksheetTable", WorksheetTable)

//// App initialization
initSentry()

Vue.config.devtools = true
new Vue({el: "#app"})

initInertia({layout: HomeLayout})
