<script setup lang="ts">
import {ref} from "vue";

const videos = [
  {title: "Greg and Rosa", id: "815375076", thumbnail: "/images/tutor/greg-and-rosa.png"},
  {title: "Chelsea", id: "1051680640", thumbnail: "/images/tutor/chelsea.png"},
  {title: "Peri and Wendy", id: "1047891583", thumbnail: "/images/tutor/peri-and-wendy.png"},
  {title: "Turquoise and Londyn", id: "1001644823", thumbnail: "/images/tutor/turquoise-and-londyn.png"},
  {title: "Ashon and Angel", id: "814736371", thumbnail: "/images/tutor/ashon-and-angel.png"},
  {title: "Isa", id: "736316362", thumbnail: "/images/tutor/isa.png"},
]

const playerOpen = ref(false)
const selectedVideo = ref<typeof videos[number] | null>(null)

function popupVideo(video: typeof videos[number]) {
  selectedVideo.value = video
  playerOpen.value = true
}
</script>

<template>
  <div class="tutor-video-carousel">
    <b-carousel class="carousel" :interval="4000" controls indicators>
      <b-carousel-slide v-for="video in videos" :key="video.id">
        <template #img>
          <div class="carousel-slide" @click="popupVideo(video)">
            <b-img-lazy class="d-block img-fluid" :src="video.thumbnail" :alt="video.title"></b-img-lazy>
          </div>
        </template>
      </b-carousel-slide>
    </b-carousel>

    <!-- TODO: replace b-modal with something better (modal always sticks to the bottom no matter what I do) -->
    <b-modal
      v-model="playerOpen"
      title=""
      size="xl"
      hide-footer
      lazy
    >
      <div v-if="selectedVideo" class="player-iframe-container">
        <iframe
          :src="`https://player.vimeo.com/video/${selectedVideo.id}?autoplay=1`"
          allow="autoplay; fullscreen; picture-in-picture"
          allowfullscreen
          style="width:100%; height:100%;"
          :title="selectedVideo.title"
        ></iframe>
      </div>
    </b-modal>
  </div>
</template>

<style scoped lang="scss">
.carousel {
  margin: auto;
  min-width: 25rem;
  max-width: 25rem;
  height: 25rem;
}

.carousel-slide {
  max-height: 25rem;
  cursor: pointer;
}

.player-iframe-container {
  height: 50rem;
}
</style>
